.testimonials-container {
  padding: 60px 84px;
  max-width: 1920px;
  margin: 0 auto;
}
.testimonials-card,
.testimonials-inner-box {
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
}
.testimonials-inner-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  background: linear-gradient(160deg, #ecfaf1 0, #f2fbf5 98%);
  border-radius: 40px;
  overflow: hidden;
}
.scroll-button {
  display: none;
}
.testimonials-top-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.testimonials-text {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
  margin: 0;
}
.testimonials-cards-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
}
.testimonials-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 64px;
  margin: 0;
}
.testimonials-subheading {
  font-size: 20px;
  font-weight: 400;
  color: #323232;
  line-height: 24px;
  margin: 0;
}
.testimonials-card {
  background-color: #fff;
  border-radius: 24px;
  height: 100%;
  max-width: 400px;
  min-height: 275px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  align-items: start;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.06);
}
.testimonial-card-text {
  text-align: start;
  font-size: 18px;
  line-height: 22px;
  color: #323232;
  font-weight: 400;
  min-height: 132px;
}
.testimonial-profile-name {
  font-size: 16px;
  text-align: start;
  font-weight: 500;
  line-height: 20px;
  color: #1d1d1f;
}
.testimonial-card-profile-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.testimonial-profile-role {
  font-size: 14px;
  text-align: start;
  font-weight: 400;
  line-height: 18px;
  color: #0072dd;
}
.testimonial-card-profile,
.testimonial-card-profile-img {
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 1920px) {
  .testimonials-container {
    padding: 60px 160px;
    box-sizing: border-box;
  }
  .testimonial-card-text {
    min-height: 88px;
  }
  .testimonials-card {
    max-width: 506px;
    min-height: 250px;
  }
}
@media screen and (max-width: 1320px) {
  .testimonial-card-text {
    min-height: 176px;
  }
}
@media screen and (max-width: 1024px) {
  .testimonials-container {
    padding: 112px 32px 48px;
    position: relative;
  }
  .testimonials-inner-box {
    padding: 40px 40px 80px;
  }
  .testimonials-cards-box {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .testimonials-card {
    flex: 0 0 auto;
    scroll-snap-align: start;
    box-shadow: none;
  }
  .testimonial-card-text {
    min-height: 132px;
  }
  .testimonials-cards-box::-webkit-scrollbar {
    display: none;
  }
  .testimonials-heading {
    font-size: 40px;
    line-height: 54px;
    min-height: 100%;
  }
  .scroll-button {
    position: absolute;
    bottom: 40px;
    transform: translateY(50%);
    background-color: #efeff0;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }
  .scroll-button.active {
    background-color: #e0e0e3;
  }
  .scroll-button svg {
    color: #949494;
    transition: color 0.2s;
  }
  .scroll-button.active svg {
    color: #626263;
  }
  .scroll-button.inactive {
    background-color: #cfcfcf;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .left-button {
    right: 84px;
  }
  .right-button {
    right: 32px;
  }
  .scroll-button:hover.active {
    background-color: #c4c4c4;
  }
}
@media screen and (max-width: 768px) {
  .testimonials-container {
    padding: 72px 16px 32px;
  }
  .testimonials-inner-box {
    gap: 24px;
  }
  .testimonials-text {
    font-size: 24px;
    line-height: 28px;
  }
  .testimonials-heading {
    font-size: 40px;
    line-height: 54px;
    max-width: 90%;
  }
  .testimonials-subheading {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 600px) {
  .testimonials-container {
    padding: 72px 16px 32px;
  }
  .testimonials-inner-box {
    padding: 20px 20px 60px;
    border-radius: 20px;
  }
  .testimonials-cards-box {
    gap: 20px;
  }
  .testimonials-card {
    padding: 24px;
    max-width: 290px;
    min-height: 217px;
  }
  .testimonial-card-text {
    font-size: 14px;
    line-height: 18px;
    min-height: 108px;
  }
  .testimonials-text {
    font-size: 18px;
    line-height: 24px;
  }
  .testimonials-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .testimonial-profile-name {
    font-size: 14px;
    line-height: 18px;
  }
  .testimonial-profile-role {
    font-size: 12px;
    line-height: 18px;
  }
  .scroll-button {
    bottom: 25px;
    width: 28px;
    height: 28px;
  }
  .left-button {
    right: 60px;
  }
  .right-button {
    right: 20px;
  }
}
