.banner-container {
  position: fixed;
  bottom: 20px;
  right: 1%;
  border-radius: 16px;
  background-color: #292929;
  color: #fff;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  transform: none;
  padding: 16px 24px;
  gap: 16px;
  width: 467px;
  max-width: 467px;
}
.cookies-heading-text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}
.cookies-desc-text {
  color: #fff;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-align: left;
  min-width: 420px;
}
.banner-button-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
}
.banner-button {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
  padding: 8px 20px;
  cursor: pointer;
  align-items: center;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 400;
}
.banner-button:hover {
  background-color: #0072dd;
  color: #fff;
  border: 1px solid #0072dd;
}
.banner-accept-button {
  color: #fff;
  border: none;
  background-color: #0072dd;
  border-radius: 100px;
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}
.banner-accept-button:hover {
  opacity: 0.8;
}
@media screen and (min-width: 2560px) and (min-height: 1359px) {
  .banner-container {
    bottom: 570px;
  }
}
@media screen and (width: 768px) and (height: 1024px) {
  .banner-container {
    bottom: 10px !important;
  }
}
@media screen and (max-width: 950px) {
  .banner-container {
    bottom: 150px;
  }
}
@media screen and (max-width: 600px) {
  .banner-container {
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 16px;
    gap: 8px;
    width: 90%;
    max-width: 90%;
    z-index: 1001;
  }
  .cookies-desc-text {
    min-width: 100%;
    width: 100%;
  }
}
