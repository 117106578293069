.drawer-white-icon,
.navbar {
  -webkit-tap-highlight-color: transparent;
}
.nav-item-text:hover,
.navbar,
.navbar.scrolled {
  background-color: #fff;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 84px;
  height: 40px;
  position: fixed;
  inset: 0;
  z-index: 999;
  transition: background-color 0.3s;
  backdrop-filter: blur(35px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
}
.nav-inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
.bataiyo-logo-img {
  cursor: pointer;
  height: 40px;
  width: 136px;
}
.drawer-white-icon {
  color: #fff;
  font-size: 18px !important;
  height: 20px !important;
}
.nav-items {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-item-text {
  display: inline;
  color: #525252;
  font-weight: 400;
  font-size: 18px;
  padding: 4px 8px;
  text-decoration: none;
}
.nav-item-text:hover {
  border-radius: 4px;
}
.active-nav-item-link {
  color: #0072dd;
}
.nav-mobile-screen {
  display: none;
  cursor: pointer;
}
.logo-a-tag {
  display: flex;
}
@media screen and (min-width: 1920px) {
  .navbar {
    padding: 12px 160px;
  }
  .nav-inner-box {
    max-width: 1600px;
  }
}
@media screen and (max-width: 1339px) {
  .navbar {
    padding: 12px 32px;
  }
  .nav-items {
    gap: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .nav-items {
    gap: 10px;
    display: flex;
  }
}
@media screen and (max-width: 1025px) {
  .bataiyo-logo-img,
  .mob-line-icon {
    -webkit-tap-highlight-color: transparent;
  }
  .mob-nav-social-icons-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 24px;
  }
  .mob-nav-soc-link-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 24px;
  }
  .mob-nav-soc-link {
    color: #292929;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    justify-content: start;
  }
  .mob-link-blue-arrow {
    color: #0072dd;
    font-size: 20px !important;
  }
  .active-nav-link {
    color: #0072dd !important;
  }
  .mob-nav-follow-text {
    color: #525252;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
  .mob-nav-item-text {
    display: inline;
    color: #525252;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    text-decoration: none;
  }
  .mob-line-icon {
    padding: 16px 20px calc(40px + env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 24px;
    height: 100%;
  }
  .mob-nav-hash-ids {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .mob-nav-heading-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .mob-nav-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #0072dd;
  }
  .mob-nav-bataiyo-logo {
    width: 160px;
    height: 47px;
  }
  .mob-nav-list-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    gap: 38px;
    height: 100%;
  }
  .bataiyo-logo-img {
    height: 43px;
    width: 150px;
  }
  .nav-button-text,
  .nav-items {
    display: none;
  }
  .nav-mobile-screen {
    background-color: #0072dd;
    padding: 6px;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .navbar {
    padding: 12px 16px;
  }
}
@media screen and (max-width: 600px) {
  .navbar {
    padding: 16px;
  }
  .mob-nav-item-text {
    font-size: 24px;
    line-height: 30px;
  }
}
