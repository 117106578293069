.core-values-card,
.core-values-card-icon-box {
  width: 100%;
  height: 100%;
}
.core-values-container {
  padding: 60px 84px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.core-scroll-button {
  display: none;
}
.core-values-top-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  gap: 8px;
  padding: 20px 0;
}
.core-values-matters-text {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
  margin: 0;
}
.core-values-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 64px;
  margin: 0;
}
.core-values-cards-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
}
.core-values-card {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  background-color: #f8f8f8;
  border-radius: 24px;
  padding: 40px;
  gap: 20px;
  justify-content: center;
  align-items: start;
  text-align: start;
}
.core-values-card-heading {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  color: #1d1d1f;
}
.core-values-card-description {
  font-size: 18px;
  line-height: 24px;
  color: #323232;
  font-weight: 400;
  min-height: 96px;
}
.core-values-network-icon {
  width: 98px;
  height: 40px;
}
.core-values-trust-icon {
  width: 37px;
  height: 40px;
}
.core-values-growing-icon {
  width: 54px;
  height: 40px;
}
@media screen and (min-width: 1920px) {
  .core-values-container {
    padding: 60px 160px;
    box-sizing: border-box;
  }
  .core-values-card {
    max-width: 506px;
  }
  .core-values-card-description {
    min-height: 72px;
  }
}
@media screen and (max-width: 1370px) {
  .core-values-card-description {
    min-height: 120px;
  }
}
@media screen and (max-width: 1320px) {
  .core-values-card-heading {
    min-height: 64px;
  }
}
@media screen and (max-width: 1180px) {
  .core-values-card-description {
    min-height: 192px;
  }
}
@media screen and (max-width: 1024px) {
  .core-values-container {
    padding: 48px 32px;
    position: relative;
  }
  .core-values-cards-box {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .core-values-card {
    flex: 0 0 auto;
    scroll-snap-align: start;
  }
  .core-values-cards-box::-webkit-scrollbar {
    display: none;
  }
  .core-values-top-text-box {
    max-width: 100%;
  }
  .core-values-card-heading {
    min-height: 100%;
  }
  .core-values-heading {
    font-size: 40px;
    line-height: 54px;
    min-height: 100%;
  }
  .core-values-card-description {
    min-height: 96px;
  }
  .core-scroll-button {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    background-color: #efeff0;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }
  .core-scroll-button.active {
    background-color: #e0e0e3;
  }
  .core-scroll-button svg {
    color: #949494;
    transition: color 0.2s;
  }
  .core-scroll-button.active svg {
    color: #626263;
  }
  .core-scroll-button.inactive {
    background-color: #cfcfcf;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .core-left-button {
    right: 84px;
  }
  .core-right-button {
    right: 32px;
  }
  .core-scroll-button:hover.active {
    background-color: #c4c4c4;
  }
}
@media screen and (max-width: 768px) {
  .core-values-container {
    padding: 32px 16px 48px;
    gap: 24px;
  }
  .core-values-matters-text {
    font-size: 24px;
    line-height: 28px;
  }
  .core-values-heading {
    font-size: 40px;
    line-height: 54px;
    max-width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .core-values-container {
    padding: 32px 16px;
    gap: 24px;
  }
  .core-values-top-text-box {
    padding: 16px 0;
  }
  .core-values-matters-text {
    font-size: 18px;
    line-height: 24px;
  }
  .core-values-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .core-values-cards-box {
    gap: 20px;
  }
  .core-values-card {
    gap: 12px;
    padding: 24px;
    max-width: 290px;
    box-sizing: border-box;
  }
  .core-values-network-icon {
    width: 68px;
    height: 28px;
  }
  .core-values-trust-icon {
    width: 25px;
    height: 28px;
  }
  .core-values-growing-icon {
    width: 32px;
    height: 28px;
  }
  .core-values-card-heading {
    font-size: 20px;
    line-height: 24px;
    margin-top: 8px;
  }
  .core-values-card-description {
    font-size: 14px;
    line-height: 18px;
    min-height: 90px;
  }
  .core-scroll-button {
    width: 28px;
    height: 28px;
  }
  .core-left-button {
    right: 60px;
  }
  .core-right-button {
    right: 20px;
  }
}
