.job-hero-container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  align-items: center;
  min-height: calc(100dvh - 106px);
  padding: 32px 84px 64px 84px;
}
.job-hero-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 1440px;
}
.job-hero-heading {
  color: #1d1d1f;
  font-weight: 600;
  font-size: 64px;
  line-height: 72px;
  margin: 0;
}
.job-hero-subheading {
  font-size: 20px;
  line-height: 24px;
  color: #525252;
  font-weight: 500;
}
.job-img-box {
  width: 100%;
  height: 100%;
  max-width: 1245px;
  max-height: 438px;
}
.job-hero-img {
  width: 100%;
  height: 100%;
}
.get-early-access-btn {
  background-color: #0072dd;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
  border: none;
  padding: 15px 58px;
  cursor: pointer;
}
.hire-category-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  align-items: center;
  padding: 96px 84px 0 84px;
  box-sizing: border-box;
}
.hire-category-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 48px;
  justify-content: center;
  align-items: center;
}
.hire-category-img-box {
  max-width: 700px;
  max-height: 500px;
  width: 100%;
  height: 100%;
}
.hire-category-img {
  width: 100%;
  height: 100%;
}
.advantage-scroller-box {
  display: none;
}
.job-advantage-img-box {
  max-width: 489px;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.job-advantage-img {
  width: 100%;
  height: 100%;
}
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  align-items: center;
  padding: 32px 84px 0 84px;
}
.dashboard-text-box {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
  line-height: 64px;
}
.dashboard-img-box {
  width: 100%;
  height: 100%;
  max-width: 1244px;
  max-height: 886px;
}
.dashboard-img {
  width: 100%;
  height: 100%;
  max-width: 1144px;
  max-height: 786px;
}
.contact-us-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
  line-height: 64px;
}
.contact-us-container {
  padding: 48px 84px 96px 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}
.contact-us-form-box {
  display: flex;
  justify-content: space-between;
  gap: 28px;
  width: 100%;
  align-items: start;
}
.job-social-Icons-box {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 12px;
  width: 100%;
}
.contact-us-flex {
  flex: 1;
  width: 100%;
  text-align: start;
}
.contact-us-text-flex {
  flex: 1;
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
}
.contact-us-desc-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #525252;
  max-width: 554px;
}
.social-Icons-img-bg {
  background-color: #0072dd;
  padding: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-Icon-size {
  font-size: 35px !important;
}
.whats-app-icon {
  display: flex;
  background-color: #60d66921;
  padding: 12px;
  border-radius: 8px;
  gap: 4px;
  color: #292929;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  flex-direction: column;
  text-align: start;
  width: 100%;
  max-width: 354px;
  justify-content: center;
}
.whatsapp-no {
  color: #292929;
  font-size: 14px;
  line-height: 21px;
}
.mobile-heading {
  display: none;
}
.job-advantage-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media screen and (min-width: 1920px) {
  .job-hero-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 48px 160px;
    min-height: fit-content;
    box-sizing: border-box;
  }
  .dashboard-container {
    padding: 32px 160px 0 160px;
    box-sizing: border-box;
  }
  .hire-category-container {
    padding: 96px 160px 0 160px;
    box-sizing: border-box;
  }
  .contact-us-container {
    padding: 48px 160px 96px 160px;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .advantage-scroller-box {
    display: none;
  }
}
@media (max-width: 1360px) and (max-height: 768px) {
  .job-hero-container {
    padding: 32px 96px;
  }
  .job-hero-text-box {
    max-width: 1096px;
  }
  .advantage-scroller-box {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .job-hero-container {
    padding: 32px 70px;
    gap: 64px;
    min-height: fit-content;
  }
  .contact-us-heading {
    font-size: 40px;
    line-height: 54px;
  }
  .job-hero-heading {
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
  }
  .dashboard-text-box {
    font-size: 40px;
    line-height: 54px;
  }
  .job-hero-subheading {
    font-size: 18px;
    line-height: 24px;
  }
  .hire-category-container {
    gap: 64px;
    padding: 48px 32px;
  }
  .hire-category-img-container {
    gap: 64px;
  }
  .advantage-scroller-box {
    display: none;
  }
  .dashboard-container {
    gap: 64px;
    padding: 48px 32px;
  }
  .contact-us-container {
    padding: 48px 32px 48px 32px;
    gap: 64px;
  }
  .contact-us-form-box {
    align-items: center !important;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .web-heading {
    display: none;
  }
  .mobile-heading {
    display: block;
  }
}
@media screen and (max-width: 980px) {
  .dashboard-container {
    gap: 64px;
    padding: 62px 32px 0 32px;
  }
  .categories-lg-box {
    display: none;
  }
  .advantage-scroller-box {
    display: block;
    padding: 0 32px 48px 32px;
  }
  .hire-category-img-box {
    max-width: 100%;
    max-height: 100%;
    margin-top: 22px;
  }
}
@media screen and (max-width: 600px) {
  .job-hero-container {
    padding: 24px 16px 32px 16px;
    gap: 16px;
  }
  .job-hero-text-box {
    max-width: 100%;
    gap: 16px;
  }
  .job-hero-section-text-box {
    gap: 16px;
  }
  .hire-category-img-box {
    margin-top: 48px;
  }
  .dashboard-text-box {
    font-size: 28px;
    line-height: 32px;
  }
  .job-hero-heading {
    font-size: 32px;
    line-height: 38px;
  }
  .job-hero-subheading {
    font-size: 16px;
    line-height: 20px;
  }
  .get-early-access-btn {
    padding: 8px 16px;
  }
  .job-img-box {
    max-width: 100%;
    max-height: 100%;
    margin-top: 8px;
  }
  .advantage-scroller-box {
    padding: 0 16px;
  }
  .hire-category-container {
    padding: 64px 16px 0 16px;
    gap: 24px;
  }
  .dashboard-container {
    gap: 24px;
    padding: 16px;
  }
  .contact-us-heading {
    font-size: 32px;
    line-height: 40px;
  }
  .contact-us-container {
    padding: 32px 16px 48px 16px;
    gap: 24px;
  }
  .social-Icons-img-bg {
    padding: 10px;
  }
  .social-Icon-size {
    font-size: 24px !important;
  }
  .contact-us-desc-text {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  .dashboard-img {
    width: 100%;
    height: 100%;
  }
  .whats-app-icon {
    max-width: 80%;
    text-align: center;
  }
  .job-social-Icons-box {
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  .job-advantage-img-box {
    display: none;
  }
  .mobile-heading {
    display: block;
  }
}
