.roadmap-section {
  display: flex;
  flex-direction: column;
  padding: 60px 84px;
  gap: 64px;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
}
.roadmap-top-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 968px;
  gap: 8px;
  padding: 20px 0;
}
.roadmap-subheading-text {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
  margin: 0;
}
.roadmap-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 64px;
  margin: 0;
}
.roadmap-card-qtext,
.roadmap-card-title {
  font-size: 18px;
  line-height: 22px;
  color: #323232;
}
.roadmap-image {
  max-width: 1200px;
  height: 224px;
}
.roadmap-box-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  height: 100%;
  position: relative;
  width: 100%;
  padding-bottom: 20px;
}
.horz-blue-line {
  left: 110px;
  position: absolute;
  top: 27%;
  width: calc(100% - 220px);
  height: 3px;
  background: repeating-linear-gradient(
    to right,
    #e6e8ec 0,
    #e6e8ec 12px,
    transparent 12px,
    transparent 24px
  );
}
.roadmap-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 228px;
  height: 100%;
  gap: 24px;
  position: relative;
}
.roadmap-dot-image,
.roadmap-image-container {
  width: 30px;
  height: 30px;
}
.roadmap-card-desc,
.roadmap-card-qtext,
.roadmap-card-title {
  text-align: center;
  width: 150px;
}
.roadmap-card-title {
  font-weight: 400;
  height: 24px;
}
.roadmap-card-qtext-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.roadmap-card-qtext {
  font-weight: 500;
  padding: 8px 20px;
  border: 1px solid #e6e8ec;
  border-radius: 48px;
  box-sizing: border-box;
  background: linear-gradient(250deg, #eff5fd 0, #fafafa 100%);
}
.roadmap-card-desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #1d1d1f;
  height: 56px;
}
@media screen and (min-width: 1920px) {
  .roadmap-section {
    padding: 96px 160px 60px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1330px) {
  .roadmap-box-container {
    padding-bottom: 48px;
    flex-wrap: nowrap;
  }
  .horz-blue-line {
    top: 24.5%;
  }
}
@media screen and (max-width: 1024px) {
  .roadmap-section {
    padding: 48px 70px 0 96px;
  }
  .roadmap-top-text-box {
    max-width: 100%;
  }
  .roadmap-heading {
    font-size: 40px;
    line-height: 54px;
  }
}
@media screen and (max-width: 1023px) {
  .roadmap-section {
    padding: 0 16px;
    margin-top: 64px;
    gap: 48px;
  }
  .roadmap-card-title {
    font-size: 16px;
    width: 140px;
  }
  .roadmap-card-qtext {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #292929;
    width: 100%;
  }
  .roadmap-card-desc {
    font-size: 18px;
    line-height: 22px;
    width: 140px;
  }
  .horz-blue-line {
    left: 90px;
    width: calc(100% - 180px);
  }
}
@media screen and (max-width: 768px) {
  .roadmap-section {
    margin: 32px 0;
  }
  .roadmap-box-container {
    padding-bottom: 32px;
  }
}
@media screen and (max-width: 767px) {
  .roadmap-section {
    gap: 42px;
  }
  .roadmap-image-container {
    width: 100%;
    height: 100%;
  }
  .orange-horx-line {
    width: 150px;
  }
  .horz-blue-line {
    z-index: -1;
    left: 48%;
    position: absolute;
    top: 8%;
    width: 2px;
    height: calc(100% - 75px);
    background: repeating-linear-gradient(
      to bottom,
      #e6e8ec 0,
      #e6e8ec 12px,
      transparent 12px,
      transparent 24px
    );
  }
  .roadmap-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    width: 100%;
    max-width: 350px;
    position: relative;
    padding-bottom: 16px;
  }
  .roadmap-mobile-horz-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    width: 100%;
  }
  .roadmap-mobile-phase-text {
    min-width: 54px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #323232;
  }
  .roadmap-card-qtext-box {
    gap: 8px;
  }
  .roadmap-card-qtext {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #323232;
    padding: 4px 16px;
    width: 115px;
  }
  .roadmap-card-desc {
    font-size: 16px;
    line-height: 20px;
    width: 128px;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .roadmap-top-text-box {
    padding: 16px 0;
  }
  .roadmap-mobile-container {
    max-width: 310px;
  }
  .roadmap-section {
    padding: 0 16px;
    gap: 42px;
    margin-top: 64px;
    margin-bottom: 32px;
  }
  .roadmap-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .roadmap-subheading-text {
    font-size: 18px;
    line-height: 24px;
  }
  .horz-blue-line {
    left: 148px;
  }
}
@media screen and (max-width: 350px) {
  .roadmap-mobile-horz-box {
    gap: 12px;
  }
  .roadmap-card-desc {
    font-size: 14px;
    line-height: 18px;
    width: 73px;
    height: 100%;
  }
  .horz-blue-line {
    left: 56.5%;
  }
}
