.App {
  text-align: center;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 91px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html::-webkit-scrollbar-track {
  background: #f1f1f1;
}
html::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.MuiFormHelperText-root.Mui-error {
  font-family: Outfit, sans-serif;
  font-size: 12px;
}
.dob-errors,
.location-errors {
  font-family: Outfit, sans-serif;
  font-size: 12px;
  color: #d32f2f;
  align-self: start;
  letter-spacing: 0.03333em;
  line-height: 1.66;
}
.location-errors {
  margin: 3px 14px 0;
}
.dob-errors {
  margin: -3px 14px 0;
}
.form-tnc-privacy-link {
  color: #0052cc;
  text-decoration: underline;
}
.flag-background-image {
  background-image: url("https://d2uvzz1jka6usw.cloudfront.net/bataiyobgimg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.job-background-gradient {
  background: linear-gradient(to right, #fef0e3 0, #ffffff03 50%, #f0fff6 100%);
  backdrop-filter: blur(130px);
}
.blog-title {
  color: #525252;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: start;
  width: 100%;
  min-height: 40px;
}
.blogHeadParaStyle,
.blogParaStyle {
  color: #525252;
  font-weight: 400;
  margin: 16px 0 32px;
}
.blogHeadParaStyle {
  font-size: 18px;
  line-height: 22px;
}
.blogParaStyle {
  font-size: 16px;
  line-height: 20px;
}
.blogHeadingStyles {
  font-size: 20px;
  line-height: 25px;
  color: #292929;
  font-weight: 500;
  margin: 0;
}
.blogListStyles {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  margin: 8px 0 16px;
}
.blogUlListStyles {
  margin: 0;
}
.MuiAutocomplete-popper {
  background-color: #fbfbfb !important;
}
.layout-main-box {
  margin-top: 64px;
}
.privacy-link-wrapper {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  white-space: normal;
}
@media screen and (max-width: 2560px) {
  .blog-title {
    min-height: 60px;
  }
}
@media screen and (min-width: 1921px) {
  .layout-main-box {
    margin-top: 72px;
  }
}
@media screen and (max-width: 1670px) {
  .blog-title {
    min-height: 80px;
  }
}
@media screen and (max-width: 1420px) {
  .blog-title {
    min-height: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .blog-title {
    min-height: 80px;
  }
}
@media screen and (max-width: 820px) {
  .flag-background-image {
    background-image: url("https://d2uvzz1jka6usw.cloudfront.net/mobbgimg.png");
  }
}
@media screen and (max-width: 600px) {
  .blogHeadParaStyle,
  .blogParaStyle {
    font-size: 14px;
    line-height: 18px;
  }
  .blog-title {
    min-height: auto;
  }
  .blogParaStyle {
    margin: 12px 0 24px;
  }
  .blogHeadingStyles {
    font-size: 18px;
    line-height: 24px;
    margin: 0.4rem 0;
  }
  .blogListStyles {
    font-size: 16px;
    line-height: 26px;
    color: #000;
    font-weight: 400;
  }
  .layout-main-box {
    margin-top: 72px;
  }
}
