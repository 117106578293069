.footer {
  padding: 48px 84px;
  background-color: #292929;
  -webkit-tap-highlight-color: transparent;
}
.footer-inner-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.bataiyo-logo {
  height: 62px;
  width: 221px;
}
.footer-first-row {
  display: flex;
  text-align: start;
  justify-content: space-between;
  align-items: center;
}
.footer-subheading {
  margin-top: 20px;
  margin-left: 6px;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  color: #f5f5f5;
}
.footer-second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}
.about-section {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}
.links-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}
.links {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
}
a {
  text-decoration: none;
  color: #ffff;
}
.privacy {
  display: flex;
  gap: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #f5f5f5;
}
.social-icons {
  display: flex;
  gap: 24px;
  justify-content: end;
}
.copyright {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #f5f5f5;
}
.global-en {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
@media screen and (min-width: 1920px) {
  .footer {
    padding: 48px 160px 64px 160px;
  }
  .footer-inner-box {
    max-width: 1600px;
  }
}
@media screen and (max-width: 1439px) {
  .privacy {
    gap: 18px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1339px) {
  .footer {
    padding: 48px 32px 64px 32px;
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    padding: 32px 32px;
  }
  .footer-inner-box {
    gap: 32px;
  }
  .footer-first-row {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
  }
  .footer-subheading {
    width: auto;
    font-size: 20px;
  }
  .links-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .bataiyo-logo {
    height: 43px;
    width: 160px;
  }
  .social-icons {
    display: flex;
    gap: 24px;
    justify-content: start;
  }
  .footer-second-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
  .privacy {
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: center;
  }
}
@media screen and (max-width: 1023px) {
  .footer {
    padding: 32px 32px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 32px 16px;
  }
  .footer-second-row {
    padding: 16px 0;
    gap: 16px;
  }
  .privacy {
    gap: 8px;
  }
  .privacy a {
    padding: 15px 4px;
  }
}
@media screen and (max-width: 600px) {
  .footer {
    padding: 32px 16px;
  }
  .footer-inner-box {
    gap: 18px;
  }
  .footer-first-row {
    gap: 18px;
  }
}
@media screen and (max-width: 430px) {
  .footer-subheading {
    margin-top: 10px;
    font-size: 18px;
  }
  .footer-first-row {
    align-items: center;
  }
  .social-icons {
    justify-content: center;
  }
}
