.hero-group-img,
.hero-img-box,
.loader-overlay {
  width: 100%;
  height: 100%;
}
.hero-section {
  height: 100%;
  gap: 48px;
  padding: 48px 84px 24px;
  max-width: 1920px;
  margin: 0 auto;
}
.hero-section-inner-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(-45deg, #eff5fd 0, #fafafa 100%);
  border-radius: 40px;
  padding: 38px 0 18px 64px;
}
.hero-img-box {
  max-width: 700px;
  max-height: 629px;
}
.hero-text-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 550px;
  align-items: center;
  justify-content: center;
  text-align: start;
  position: relative;
  z-index: 1;
}
.hero-heading {
  color: #1d1d1f;
  font-weight: 600;
  font-size: 58px;
  line-height: 72px;
  margin: 0;
}
.hero-subheading-box {
  align-self: start;
  flex-direction: column;
  display: flex;
  gap: 8px;
  text-align: center;
  justify-content: center;
  align-items: start;
}
.hero-subheading-list {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.hero-subheading {
  color: #323232;
  font-weight: 400;
  line-height: 24px;
  font-size: 20px;
  text-align: start;
  margin: 0;
}
.hero-download-btn-box {
  align-self: flex-start;
  margin-top: 8px;
}
.right-arrow-icon {
  font-size: 16px !important;
  margin-left: 8px;
  color: #fff;
  font-weight: 500;
}
.hero-download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1570ef;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: Lexend, sans-serif;
}
.hero-download-btn:hover {
  opacity: 0.9;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.bataiyo-loader-gif {
  height: 100px;
  width: 100px;
}
.hero-green-text {
  color: #06a445;
}
.hero-orange-text {
  color: #ff820f;
}
.hero-blue-text {
  color: #0072dd;
}
.hero-values-span,
.hero-values-text {
  color: #292929;
  font-size: 16px;
  line-height: 20px;
}
.hero-logo-section-container {
  background-color: #ebf4ff;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  border-top: 1px solid #0072dd;
  border-bottom: 1px solid #0072dd;
}
.hero-values-box {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
}
.hero-values-text {
  font-weight: 400;
}
.hero-values-span {
  font-weight: 700;
}
.hero-check-img,
.hero-subheading-icon {
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 1920px) {
  .hero-section {
    min-height: fit-content;
    padding: 48px 160px 24px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1360px) {
  .hero-heading {
    font-size: 48px;
    line-height: 62px;
  }
  .hero-text-box {
    max-width: 470px;
  }
  .hero-section-inner-box {
    padding: 28px 0 32px 32px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-section {
    justify-content: start;
    padding: 48px 32px;
    min-height: min-content;
  }
  .hero-section-inner-box {
    padding: 48px 0 32px 32px;
  }
  .hero-text-box {
    max-width: 380px;
  }
  .hero-heading {
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
  }
  .hero-subheading {
    line-height: 22px;
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .hero-section {
    padding: 32px;
  }
  .hero-section-inner-box {
    flex-direction: column;
    gap: 28px;
  }
  .hero-text-box {
    max-width: 500px;
  }
}
@media screen and (max-width: 600px) {
  .hero-section {
    margin: 92px 0 32px;
    justify-content: start;
    gap: 24px;
    padding: 0 16px;
    min-height: min-content;
  }
  .hero-subheading-list {
    gap: 8px;
  }
  .hero-section-inner-box {
    padding: 32px 12px 18px;
    flex-direction: column;
    gap: 32px;
    border-radius: 20px;
  }
  .hero-text-box {
    max-width: 305px;
    align-items: center;
    gap: 24px;
  }
  .hero-heading {
    font-size: 32px;
    line-height: 38px;
    text-align: start;
  }
  .hero-download-btn-box {
    align-self: center;
  }
  .hero-subheading {
    line-height: 18px;
    font-size: 14px;
  }
  .hero-check-img,
  .hero-subheading-icon {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 360px) {
  .hero-text-box {
    max-width: 280px;
    align-items: center;
    gap: 16px;
  }
  .hero-heading {
    font-size: 28px;
    line-height: 32px;
    max-width: 100%;
  }
}
