.six-degree-section {
  margin: 0 auto;
  padding: 96px 84px 60px;
  max-width: 1920px;
  box-sizing: border-box;
}
.six-degree-section-inner-box {
  display: flex;
  padding: 48px 64px 0;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(35deg, #fafafa 0, #fafafa 75%, #eff5fd 100%);
  border-radius: 40px;
  gap: 24px;
}
.idea-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.idea-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
  line-height: 64px;
}
.orange-horx-line {
  width: 246px;
  height: 11px;
}
.six-degree-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.six-degree-section-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 584px;
  text-align: start;
  width: 45%;
  gap: 24px;
}
.six-degree-heading {
  font-size: 36px;
  font-weight: 500;
  color: #323232;
  line-height: 48px;
  margin: 0;
}
.six-degree-link {
  color: #0072dd;
}
.six-degree-link:hover {
  color: #04a;
  text-decoration: underline;
}
.six-degree-para {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
}
.six-degree-img-container {
  position: relative;
}
.six-degree-img {
  max-width: 511px;
}
@media screen and (min-width: 1920px) {
  .six-degree-section {
    padding: 96px 160px 24px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1024px) {
  .six-degree-section {
    padding: 48px 32px;
  }
  .six-degree-section-inner-box {
    padding: 32px;
  }
  .six-degree-section-container {
    gap: 8px;
    flex-direction: column;
  }
  .six-degree-section-content {
    width: 100%;
    max-width: 80%;
  }
  .idea-heading {
    font-size: 40px;
    line-height: 54px;
  }
  .six-degree-heading {
    font-size: 32px;
    line-height: 38px;
  }
  .six-degree-para {
    font-size: 18px;
    line-height: 22px;
  }
  .six-degree-img {
    max-width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .six-degree-section {
    padding: 32px 16px;
  }
}
@media screen and (max-width: 600px) {
  .six-degree-section {
    padding: 0 16px;
    margin: 72px 0 32px;
    gap: 0;
  }
  .idea-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .six-degree-heading {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .orange-horx-line {
    width: 120px;
  }
  .six-degree-section-inner-box {
    padding: 20px;
    border-radius: 20px;
  }
  .six-degree-section-content {
    width: 100%;
    max-width: 100%;
  }
  .six-degree-para {
    font-size: 16px;
    line-height: 20px;
  }
  .six-degree-img {
    max-width: 350px;
  }
}
@media screen and (max-width: 430px) {
  .six-degree-img {
    max-width: 300px;
  }
}
