.super-app-container {
  padding: 60px 84px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.super-app-box {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 40px;
  padding: 64px 0 64px 64px;
  background-image: url("https://d2uvzz1jka6usw.cloudfront.net/super-app-bg-img.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1d1d1f;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  text-align: start;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  min-height: 428px;
}
.super-app-heading-box {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 24px;
  max-width: 50%;
}
.super-app-heading {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  margin: 0;
}
.super-app-subheading {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 22px;
  margin: 0;
}
.super-app-store-img {
  width: 140px;
  height: 40px;
}
.super-app-store-img-box {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 12px;
}
.super-app-sec-img {
  width: 100%;
  height: 100%;
  max-width: 426px;
  max-height: 350px;
  position: absolute;
  right: 8%;
  bottom: 0;
}
@media screen and (min-width: 1920px) {
  .super-app-container {
    padding: 60px 160px;
    box-sizing: border-box;
  }
  .super-app-heading-box {
    max-width: 58%;
  }
}
@media screen and (max-width: 1124px) {
  .super-app-sec-img {
    right: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .super-app-container {
    padding: 48px 32px;
    position: relative;
  }
  .super-app-sec-img {
    right: 5%;
  }
}
@media screen and (max-width: 1023px) {
  .super-app-sec-img {
    right: 5%;
  }
}
@media screen and (max-width: 850px) {
  .super-app-box {
    justify-content: start;
    min-height: 620px;
    padding: 32px 0 32px 32px;
    gap: 24px;
  }
  .super-app-heading-box {
    gap: 24px;
    max-width: 100%;
  }
  .super-app-sec-img {
    right: 8%;
    max-width: 400px;
    max-height: 325px;
  }
}
@media screen and (max-width: 768px) {
  .super-app-container {
    padding: 32px 16px;
    gap: 24px;
  }
  .super-app-box {
    padding: 32px;
  }
}
@media screen and (max-width: 600px) {
  .super-app-container {
    padding: 32px 16px;
  }
  .super-app-box {
    min-height: 520px;
    padding: 24px;
    gap: 24px;
    border-radius: 20px;
    background-image: url("https://d2uvzz1jka6usw.cloudfront.net/super-app-bg-mob-img.png");
  }
  .super-app-heading-box {
    gap: 12px;
  }
  .super-app-sec-img {
    right: 10%;
    max-width: 296px;
    max-height: 270px;
  }
  .super-app-heading {
    font-size: 20px;
    line-height: 24px;
  }
  .super-app-subheading {
    font-size: 14px;
    line-height: 18px;
  }
  .super-app-store-img {
    width: 112px;
    height: 32px;
  }
}
@media screen and (max-width: 365px) {
  .super-app-box {
    min-height: 540px;
  }
  .super-app-sec-img {
    right: 4%;
    max-width: 296px;
    max-height: 240px;
  }
}
