html.lenis {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
.grecaptcha-badge {
  visibility: hidden;
  position: fixed;
  bottom: 0 !important;
  right: 0;
  z-index: 1301;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior: none;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
