.recommendation-matters-container {
  padding: 96px 84px 24px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;
}
.recommendation-top-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 968px;
  gap: 8px;
  padding: 20px 0;
}
.recommendation-matters-text {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
  margin: 0;
}
.recommendation-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 64px;
  margin: 0;
}
.recommendation-images-main-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  overflow: hidden;
}
.recommendation-friends-box {
  height: 536px;
  width: 100%;
  max-width: 620px;
  max-height: 536px;
  box-sizing: border-box;
  background: linear-gradient(120deg, #eff5fd 0, #fafafa 100%);
  border: 1px solid #f1f3f6;
  border-radius: 24px;
  display: flex;
  padding: 40px 0 0 40px;
  justify-content: space-between;
  gap: 8px;
}
.recommendation-friends-text-box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  gap: 20px;
}
.recommended-user-img {
  width: 40px;
  height: 40px;
}
.recommended-friend-text {
  font-size: 28px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 32px;
  max-width: 360px;
}
.recommended-friend-img-box {
  align-self: flex-end;
  position: relative;
  display: flex;
}
.recommended-friend-img {
  width: 100%;
  height: 100%;
  max-width: 412px;
  max-height: 100%;
  border-bottom-right-radius: 24px;
}
.recommendation-service-providers-box {
  height: 536px;
  width: 100%;
  max-width: 620px;
  max-height: 536px;
  box-sizing: border-box;
  background-color: #fafafa;
  border: 1px solid #f1f3f6;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 40px 40px;
  gap: 10px;
  position: relative;
}
.recommendation-service-providers-img-box {
  height: 100%;
  width: 100%;
}
.recommendation-service-providers-img {
  height: 100%;
  width: 100%;
  max-width: 375px;
  max-height: 375px;
}
.recommendation-providers-text-box {
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  align-items: start;
  text-align: start;
}
.recommendation-providers-heading {
  font-size: 28px;
  font-weight: 600;
  color: #1d1d1f;
  line-height: 32px;
}
.recommendation-providers-subheading {
  font-size: 18px;
  font-weight: 400;
  color: #323232;
  line-height: 24px;
}
.recommendation-service-text-img {
  position: absolute;
  width: 115px;
  height: auto;
  left: 16%;
  top: 6%;
}
.recommendation-service-like-img {
  position: absolute;
  width: 58px;
  height: 58px;
  right: 26%;
  top: 6%;
}
@media screen and (min-width: 1920px) {
  .recommendation-matters-container {
    padding: 96px 160px 24px;
    box-sizing: border-box;
  }
  .recommendation-friends-box {
    height: 620px;
    max-width: 780px;
    max-height: 620px;
    padding: 50px 0 0 50px;
  }
  .recommended-friend-text {
    font-size: 40px;
    line-height: 48px;
    max-width: 400px;
  }
  .recommended-friend-img {
    max-width: 422px;
  }
  .recommendation-service-providers-box {
    height: 620px;
    width: 100%;
    max-width: 780px;
    max-height: 620px;
    padding: 42px 50px 50px;
  }
  .recommendation-service-providers-img-box {
    height: 100%;
    width: 100%;
  }
  .recommendation-service-providers-img {
    max-width: 430px;
    max-height: 430px;
  }
  .recommendation-providers-heading {
    font-size: 32px;
    line-height: 34px;
  }
  .recommendation-providers-subheading {
    font-size: 22px;
    line-height: 28px;
  }
  .recommendation-service-text-img {
    width: 145px;
    height: 96px;
    left: 18%;
    top: 6%;
  }
  .recommendation-service-like-img {
    position: absolute;
    width: 64px;
    height: 64px;
    right: 28%;
    top: 8%;
  }
}
@media screen and (max-width: 1350px) {
  .recommended-friend-text {
    font-size: 28px;
    line-height: 28px;
  }
  .recommendation-friends-box {
    flex-direction: column;
  }
  .recommended-friend-img {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-width: 218px;
  }
  .recommendation-service-providers-img {
    max-width: 340px;
    max-height: 340px;
  }
  .recommendation-service-text-img {
    left: 10%;
    top: 3%;
  }
}
@media screen and (max-width: 1024px) {
  .recommendation-top-text-box {
    max-width: 100%;
  }
  .recommendation-matters-container {
    padding: 48px 32px;
  }
  .recommendation-heading {
    font-size: 40px;
    line-height: 54px;
  }
  .recommendation-images-main-box {
    flex-direction: column;
  }
  .recommendation-service-text-img {
    left: 16%;
  }
}
@media screen and (max-width: 768px) {
  .recommendation-matters-container {
    padding: 32px 16px;
    gap: 24px;
  }
}
@media screen and (max-width: 600px) {
  .recommendation-matters-container {
    padding: 32px 16px;
    gap: 64px;
    position: relative;
  }
  .recommendation-images-main-box {
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 20px;
    flex-direction: row;
    padding-bottom: 16px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .recommendation-top-text-box {
    padding: 16px 0;
  }
  .recommendation-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .recommendation-providers-subheading {
    font-size: 14px;
    line-height: 18px;
  }
  .recommendation-friends-text-box,
  .recommendation-providers-text-box {
    gap: 12px;
  }
  .recommended-user-img {
    width: 28px;
    height: 28px;
  }
  .recommended-friend-img-box {
    margin-top: 0;
    align-self: center;
  }
  .recommendation-images-main-box::-webkit-scrollbar {
    display: none;
  }
  .recommended-friend-img {
    max-width: 244px;
    max-height: 100%;
  }
  .recommendation-friends-box,
  .recommendation-service-providers-box {
    flex: 0 0 80%;
    scroll-snap-align: center;
    flex-direction: column;
    height: 500px;
    gap: 0;
    border-radius: 20px;
  }
  .recommended-friend-text {
    font-size: 20px;
    line-height: 24px;
    max-width: 237px;
  }
  .recommendation-service-providers-box {
    padding: 24px;
    gap: 10%;
    justify-content: space-evenly;
  }
  .recommendation-service-providers-img-box,
  .recommended-friend-img-box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: end;
    align-self: flex-end;
  }
  .recommendation-matters-text {
    font-size: 18px;
    line-height: 24px;
  }
  .recommendation-providers-heading {
    font-size: 20px;
    line-height: 24px;
  }
  .recommendation-friends-box {
    padding: 24px 0 0 24px;
  }
  .recommendation-service-providers-img {
    max-width: 340px;
    max-height: 340px;
  }
  .recommendation-service-text-img {
    left: 6%;
    top: 4%;
    width: 82px;
    height: 60px;
  }
  .recommendation-service-like-img {
    right: 16%;
    top: 3%;
  }
  .scroll-button {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    background-color: #efeff0;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }
  .scroll-button.active {
    background-color: #e0e0e3;
  }
  .scroll-button svg {
    color: #949494;
    transition: color 0.2s;
  }
  .scroll-button.active svg {
    color: #626263;
  }
  .scroll-button.inactive {
    background-color: #cfcfcf;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .left-button {
    right: 60px;
  }
  .right-button {
    right: 20px;
  }
  .scroll-button:hover.active {
    background-color: #c4c4c4;
  }
}
@media screen and (max-width: 420px) {
  .recommendation-service-text-img {
    left: 1%;
    top: 5%;
  }
}
