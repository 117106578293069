.why-bataiyo-container {
  padding: 96px 84px 24px;
  max-width: 1920px;
  min-height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.why-bataiyo-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
  line-height: 64px;
}
.why-bataiyo-card-box {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  min-height: 300px;
}
.peer-to-peer-card-box,
.zero-commission-card-box {
  height: 100%;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}
.why-bataiyo-card-inner-box {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 300px;
}
.zero-commission-card-box {
  max-width: 400px;
  max-height: 300px;
  background-color: #0072dd;
  border: 1px solid #eff3ff;
  border-radius: 12px;
  padding: 44px 20px 20px;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
.zero-commission-img {
  height: 100%;
  width: 100%;
  max-height: 157px;
  max-width: 218px;
}
.card-text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
}
.commission-card-heading {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.commission-card-subheading {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.peer-to-peer-card-box {
  max-width: 400px;
  max-height: 300px;
  background: linear-gradient(15deg, #fff 50%, #e2f0ff 100%);
  border: 1px solid #eff3ff;
  border-radius: 12px;
  padding: 20px 20px 0;
  gap: 8px;
  justify-content: start;
  align-items: center;
}
.peer-to-peer-title {
  color: #323232;
  font-size: 20px;
  font-weight: 500;
}
.peer-to-peer-description {
  color: #0072dd;
  font-size: 18px;
  font-weight: 400;
}
.peer-to-peer-img {
  width: 100%;
  max-width: 223px;
  aspect-ratio: 651/633;
  object-fit: cover;
  height: auto;
}
.super-app-card-box {
  width: 100%;
  height: 300px;
  max-width: 400px;
  background: linear-gradient(-50deg, #fff 50%, #e6f6ec 100%);
  border: 1px solid #e6f6ec;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.super-app-title {
  color: #323232;
  font-size: 20px;
  font-weight: 500;
  align-self: start;
  text-align: start;
}
.super-app-img {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 270px;
  aspect-ratio: 852/778;
  object-fit: cover;
  top: 50px;
  right: 10px;
}
@media screen and (min-width: 1920px) {
  .why-bataiyo-card-inner-box,
  .zero-commission-card-box {
    max-width: 505px;
    max-height: 375px;
  }
  .why-bataiyo-container {
    padding: 96px 160px 24px;
    box-sizing: border-box;
  }
  .zero-commission-card-box {
    width: 505px;
    height: 375px;
    padding: 25px;
  }
  .commission-card-heading,
  .peer-to-peer-title,
  .super-app-title {
    font-size: 24px;
  }
  .commission-card-subheading,
  .peer-to-peer-description {
    font-size: 20px;
  }
  .zero-commission-img {
    max-height: 196px;
    max-width: 277px;
  }
  .peer-to-peer-card-box {
    width: 505px;
    height: 375px;
    max-width: 505px;
    max-height: 375px;
    padding: 25px 25px 0;
    justify-content: space-between;
  }
  .peer-to-peer-img {
    max-height: 263px;
    max-width: 271px;
  }
  .super-app-card-box {
    width: 505px;
    height: 375px;
    max-width: 505px;
    max-height: 375px;
    padding: 25px;
  }
  .super-app-img {
    max-width: 324px;
    max-height: 304px;
  }
}
@media screen and (max-width: 1350px) {
  .peer-to-peer-img {
    max-height: 225px;
    max-width: 223px;
  }
  .super-app-img {
    max-width: 220px;
    max-height: 203px;
    top: 85px;
    right: 10px;
  }
}
@media screen and (max-width: 1150px) {
  .peer-to-peer-img {
    max-height: 195px;
    max-width: 199px;
  }
}
@media screen and (max-width: 1024px) {
  .why-bataiyo-container {
    padding: 48px 32px;
  }
  .why-bataiyo-heading {
    font-size: 40px;
    line-height: 54px;
  }
}
@media screen and (max-width: 910px) {
  .super-app-img {
    max-width: 220px;
    max-height: 203px;
    top: 85px;
    right: 10px;
  }
  .peer-to-peer-img {
    max-width: 180px;
    max-height: 167px;
  }
}
@media screen and (max-width: 768px) {
  .why-bataiyo-container {
    padding: 32px 16px;
    gap: 24px;
  }
  .why-bataiyo-card-box {
    flex-direction: column;
    gap: 18px;
  }
  .why-bataiyo-card-inner-box {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
  }
  .card-text-box {
    gap: 4px;
  }
  .zero-commission-card-box {
    padding: 24px 10px 15px;
    max-width: 336px;
  }
  .commission-card-subheading,
  .peer-to-peer-description {
    font-size: 14px;
  }
  .zero-commission-img {
    max-height: 122px;
    max-width: 171px;
  }
  .peer-to-peer-card-box {
    padding: 14px 20px 0;
    max-width: 336px;
    gap: 12px;
  }
  .peer-to-peer-img {
    max-height: 164px;
    max-width: 169px;
  }
  .peer-to-peer-title,
  .super-app-title {
    font-size: 16px;
  }
  .super-app-card-box {
    padding: 14px 20px;
    max-width: 336px;
    height: 234px;
  }
  .super-app-img {
    max-width: 225px;
    max-height: 205px;
    top: 25px;
    right: 0;
  }
}
@media screen and (max-width: 600px) {
  .why-bataiyo-heading {
    font-size: 28px;
    line-height: 32px;
  }
}
@media screen and (max-width: 328px) {
  .super-app-img {
    max-width: 200px;
    max-height: 185px;
    top: 45px;
    right: 0;
  }
}
