.faq-main-container {
  padding: 60px 84px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 80px;
}
.faq-top-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
  text-align: start;
  max-width: 900px;
}
.faq-first-text {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  line-height: 28px;
  margin: 0;
}
.faq-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  line-height: 64px;
  margin: 8px 0 0;
}
.faq-bottom-box {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 48px;
  width: 100%;
}
.faq-tab-text {
  font-size: 18px !important;
  line-height: 22px !important;
  color: #6e6e73 !important;
  text-transform: none !important;
  text-align: start !important;
  font-family: Lexend !important;
  font-weight: 400 !important;
  padding: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
}
.faq-right-box {
  width: 75%;
  margin-top: -30px;
}
@media screen and (min-width: 1920px) {
  .faq-main-container {
    padding: 60px 160px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1024px) {
  .faq-main-container {
    padding: 48px 32px;
    position: relative;
    gap: 48px;
  }
  .faq-heading {
    font-size: 40px;
    line-height: 54px;
    min-height: 100%;
  }
  .faq-bottom-box {
    gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .faq-main-container {
    padding: 32px 16px;
    gap: 40px;
  }
}
@media screen and (max-width: 600px) {
  .faq-main-container {
    padding: 32px 16px;
  }
  .faq-first-text {
    font-size: 18px;
    line-height: 24px;
  }
  .faq-heading {
    font-size: 28px;
    line-height: 32px;
    margin: 12px 0 0;
  }
  .faq-bottom-box {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .faq-right-box {
    width: 100%;
    margin-top: -16px;
  }
  .faq-left-box {
    width: 100%;
    max-width: 100%;
  }
  .custom-selector {
    width: 100%;
    padding: 12px 0 12px 12px;
    border-radius: 12px;
  }
  .faq-selector-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #323232 !important;
    font-family: Lexend !important;
    min-height: 34px !important;
  }
}
