.page-not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f9f9f9;
}
.page-not-found-header {
  margin-top: 2rem;
}
.page-not-found-img-container img {
  width: 160px;
  height: 45px;
}
.not-found-first-heading {
  color: #8a4000;
  margin: 8px 0;
  font-size: 24px;
}
.not-found-first-para {
  color: #555;
  font-size: 18px;
  margin: 12px 0;
}
.not-found-second-para {
  font-size: 18px;
  margin: 0;
  color: #000;
  font-weight: 600;
}
.home-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 700;
}
.home-link:hover {
  text-decoration: underline;
}
.page-not-found-footer {
  margin-top: auto;
  padding: 1rem 0;
  background-color: #fff;
  width: 100%;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  font-size: 14px;
  color: #888;
}
