.adv-main {
  padding: 96px 96px 0 96px;
}
.adv-text-content {
  position: relative;
}
.adv-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1D1D1F;
  margin: 0;
  line-height: 64px;
}
.heading-color-text {
  color: #0072dd;
}
.advantage-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;
  height: 100%;
  padding-bottom: 40px;
}
.mobile-container {
  display: none;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 238px;
  height: 244px;
  border: 1px solid #ececed;
  border-radius: 24px;
  perspective: 1000px;
  box-shadow: 0px 27.59px 41.38px 0px #00000008;
}
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 24px;
  box-sizing: border-box;
}
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 16px;
  box-sizing: border-box;
}
.card-front {
  background-color: #ffffff;
  z-index: 2;
}
.card-back {
  background-color: #ffffff;
  transform: rotateY(180deg);
  height: 100%;
}
.card-container:hover .card-inner {
  transform: rotateY(180deg);
}
.card-flip .card-inner {
  transform: rotateY(180deg);
  -webkit-tap-highlight-color: transparent;
}
.card-title {
  margin-top: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #14171f;
  width: 140px;
  height: auto;
  width: 85%;
}
.card-description {
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #292929;
  width: 90%;
  height: auto;
}
.advantage-card-image {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 237px;
  height: 175px;
}
.heading-icon {
  position: absolute;
  top: 0;
  left: 74%;
  transform: translateY(-50%);
}
@media screen and (min-width: 1921px) {
  .adv-main {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1640px) {
  .heading-icon {
    left: 86%;
  }
}
@media screen and (max-width: 1500px) {
  .adv-main {
    padding: 120px 32px 0 32px;
  }
}

@media screen and (max-width: 1300px) {
  .adv-main {
    padding: 96px 32px 0 32px;
  }
  .heading-icon {
    left: 76%;
    width: 50px;
  }
  .advantage-container {
    margin-top: 64px;
    padding-bottom: 48px;
    flex-wrap: nowrap;
  }
  .mobile-container {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .adv-heading {
    font-size: 40px;
    line-height: 54px;
  }
}
@media screen and (max-width: 1270px) {
  .adv-main {
    margin-top: 96px;
    padding: 0 16px;
    margin-bottom: 72px;
  }
  .heading-icon {
    width: 40px;
    left: 78%;
  }
  .advantage-container {
    display: none;
  }
  .mobile-container {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .mobile-inner-container {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .mobile-inner-container::-webkit-scrollbar {
    display: none;
  }
  .card-container {
    flex: 0 0 auto;
    scroll-snap-align: start;
    align-items: center;
    border: 1px solid #ececed;
    border-radius: 24px;
    overflow: hidden;
  }
  .mob-card-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
  }
  .card-container .mob-card-inner {
    transform: rotateY(0);
    transition: transform 1s;
    -webkit-tap-highlight-color: transparent;
  }

  .card-container.mob-card-flip .mob-card-inner {
    transform: rotateY(180deg);
    transition: transform 1s;
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: 719px) {
  .heading-icon {
    left: 85%;
  }
}
@media screen and (max-width: 600px) {
  .adv-main {
    margin-top: 34px;
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .adv-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .mobile-container {
    margin-top: 24px;
  }
}
@media screen and (max-width: 450px) {
  .heading-icon {
    top: 60%;
    left: 75%;
    width: 30px;
  }
}
@media screen and (max-width: 375px) {
  .heading-icon {
    left: 78%;
  }
}
