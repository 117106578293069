.features-main-container {
  padding: 96px 96px 0 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
}
.features-img-box {
  width: 100%;
  height: 100%;
  max-width: 584px;
  max-height: 697px;
  flex: 1;
}
.features-img {
  width: 100%;
  height: 100%;
}
.features-text-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 24px;
  flex: 1;
  width: 100%;
  max-width: 584px;
}
.cross-icon-box {
  width: 100%;
  height: 100%;
  max-width: 103px;
  max-height: 27px;
  margin-bottom: 8px;
}
.cross-icon {
  width: 100%;
  height: 100%;
}
.features-heading-box {
  text-align: start;
}
.features-heading-first {
  color: #525252;
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
}
.features-heading-second {
  color: #292929;
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
}
.features-description {
  text-align: start;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #292929;
}
.jobs-app-heading {
  font-size: 56px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
  line-height: 64px;
}
.jobs-main-container {
  padding: 96px 84px 48px 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
}
.jobs-app-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}
.job-app-content-head {
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
  color: #525252;
}
.features-para {
  margin: 0;
}
@media screen and (min-width: 1920px) {
  .features-main-container {
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .jobs-main-container {
    max-width: 1920px;
    padding: 96px 160px 48px 160px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1025px) {
  .features-main-container {
    padding: 96px 48px 0;
  }
  .jobs-main-container {
    padding: 48px 32px;
  }
  .jobs-app-heading {
    font-size: 40px;
    line-height: 54px;
  }
  .job-app-content-head {
    font-size: 28px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1023px) {
  .features-main-container {
    padding: 0 32px;
    margin-top: 64px;
    flex-direction: column;
  }
  .jobs-app-container {
    flex-direction: column;
    gap: 32px;
  }
}
@media screen and (max-width: 600px) {
  .features-main-container {
    padding: 0 16px;
    margin-top: 48px;
    flex-direction: column;
    gap: 24px;
  }
  .jobs-main-container {
    padding: 0 16px 16px 16px;
    margin-top: 48px;
    gap: 24px;
  }
  .jobs-app-container {
    gap: 24px;
  }
  .features-text-box {
    gap: 16px;
  }
  .features-img-box {
    max-width: 100%;
    max-height: 100%;
  }
  .features-heading-first {
    font-size: 28px;
    line-height: 32px;
  }
  .features-heading-second {
    font-size: 32px;
    line-height: 36px;
  }
  .features-description {
    font-size: 14px;
    line-height: 18px;
  }
  .jobs-app-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .job-app-content-head {
    font-size: 24px;
    line-height: 28px;
  }
}
