.services-container {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 64px 80px;
  gap: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sticky-header {
  position: fixed;
  will-change: transform;
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1100;
  padding: 10px 16px;
  background-color: #fff;
}
.services-top-box {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0;
  position: sticky;
  top: 0;
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.services-text-box {
  width: 100%;
}
.services-heading {
  font-size: 56px;
  line-height: 64px;
  font-weight: 500;
  color: #1d1d1f;
  text-align: center;
  max-width: 968px;
  margin: 8px auto 0;
}
.services-subheading {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #323232;
  text-align: center;
  margin: 0 auto;
}
.category-item {
  font-size: 14px;
  cursor: pointer;
}
.services-search-box {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 550px;
  align-items: center;
  width: 100%;
  position: relative;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.search-input {
  padding: 8px 38px;
  width: 100%;
  border: 1px solid #e6e8ec;
  border-radius: 12px;
  height: 30px;
  font-size: 18px;
}
.search-input:focus {
  border-color: #007bff;
  outline: 0;
}
.search-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #323232;
}
.search-icon {
  position: absolute;
  left: 12px;
  width: 16px;
  height: 16px;
}
.search-buttons {
  display: flex;
  gap: 16px;
  border: none;
  position: absolute;
  right: 16px;
}
.vertical-border {
  width: 1.5px;
  background-color: #e6e8ec;
  margin: 8px 4px;
}
.clear-button {
  color: #6e6e73;
  border: none;
  font-weight: 400;
  background-color: transparent;
  padding: 9px 0;
  font-family: Lexend;
  font-size: 16px;
  cursor: pointer;
}
.category-item,
.subcategory-heading,
.subcategory-items {
  font-weight: 400;
  color: #1d1d1f;
}
.services-bottom-box {
  display: flex;
  flex: 1;
  overflow-y: auto;
  gap: 20px;
  width: 100%;
}
.left-panel {
  flex: 0 0 240px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 12px;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.service-category-all-img,
.service-category-img,
.service-category-popular-img {
  width: 20px;
  height: 20px;
}
.categories-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.category-item {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  text-align: start;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
}
.category-item:last-child,
.subcategory-container:last-child,
.subcategory-section:last-child {
  margin-bottom: 0;
}
.service-category-all-img.active {
  filter: invert(33%) sepia(8%) saturate(0%) hue-rotate(0deg) brightness(185%)
    contrast(150%);
}
.service-category-img {
  filter: invert(33%) sepia(8%) saturate(0%) hue-rotate(0deg) brightness(85%)
    contrast(120%);
}
.service-category-img.active {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(140%)
    contrast(240%);
}
.category-item.active,
.category-item.active:hover {
  background-color: #007bff;
  color: #fff;
}
.category-item:hover {
  background-color: #e6e8ec;
}
.right-panel {
  flex: 1;
  background-color: #f8f8f8;
  padding: 24px;
  overflow-y: auto;
  height: 100%;
  min-height: 75vh;
  max-height: 75vh;
  scroll-behavior: smooth;
  border-radius: 12px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  -webkit-overflow-scrolling: touch;
}
.right-panel::-webkit-scrollbar {
  width: 7px;
}
.right-panel::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.right-panel::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.right-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.all-services-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcategory-container {
  margin-bottom: 24px;
}
.subcategory-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.subcategory-heading-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 6px;
}
.subcategory-heading-inner-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  gap: 18px;
}
.subcategory-heading {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.services-horz-line {
  height: 1px;
  background-color: #e6e8ec;
  width: 100%;
  margin-bottom: 2px;
}
.subcategory-items {
  background: #fff;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-align: start;
}
.services-card-img {
  width: 24px;
  height: 24px;
}
.custom-grid-padding {
  padding-top: 16px !important;
}
@media screen and (min-width: 1920px) {
  .services-container {
    padding: 64px 160px;
  }
}
@media screen and (max-width: 1024px) {
  .services-heading {
    font-size: 40px;
    line-height: 54px;
  }
  .services-container {
    padding: 48px 32px;
  }
}
@media screen and (max-width: 768px) {
  .services-container {
    padding: 28px 16px;
    gap: 20px;
  }
  .left-panel {
    flex: 0 0 175px;
  }
  .right-panel {
    padding: 18px 12px 0;
    overflow-x: hidden;
  }
  .services-bottom-box {
    gap: 12px;
  }
  .search-input {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .services-container {
    gap: 12px;
  }
  .mobile-sticky-container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  }
  .mobile-search-container {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tabs-container,
  .tabs-scroll {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
  }
  .services-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .services-top-box {
    gap: 24px;
    padding: 16px 0;
  }
  .services-subheading {
    font-size: 18px;
    line-height: 22px;
  }
  .right-panel {
    padding: 12px;
    min-height: 100%;
    max-height: 100%;
    position: relative;
  }
  .all-services-box {
    padding-top: 8px;
  }
  .subcategory-heading {
    font-size: 14px;
    line-height: 18px;
  }
  .services-card-img {
    width: 20px;
    height: 20px;
  }
  .custom-grid-padding {
    padding-top: 8px !important;
  }
  .services-horz-line {
    margin-bottom: 4px;
  }
  .search-buttons {
    gap: 8px;
  }
  .mobile-bottom-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;
  }
  .tabs-container {
    justify-content: center;
    padding: 8px;
    gap: 10px;
    background: #f8f8f8;
    border-radius: 12px;
  }
  .tabs-scroll {
    gap: 4px;
    scroll-behavior: smooth;
  }
  .tabs-scroll::-webkit-scrollbar {
    display: none;
  }
  .tab-item {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 400;
    color: #1d1d1f;
    cursor: pointer;
    white-space: nowrap;
  }
  .tab-item.active {
    background-color: #0072dd;
    color: #fff;
  }
}
